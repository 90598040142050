import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { LayoutDefaultComponent } from '../layout-default.component';
import { ApplicationSettingsService } from '../../../services/application-settings.service';
import { AppMenuItem, AppMenuService } from '../../../services/app-menu.service';
import { ViewUtilsService } from '../../../services/view-utils.service';
import { AuthenticationService } from '../../../services/authentication.service';
import { Router } from '@angular/router';
import { GoogleAuthService } from 'app/services/google-auth.service';
import { AppNotificationServiceService } from 'app/shared/app-notification-service.service';
import { HttpErrorResponse } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { AppPlatform } from '../../../shared/globalUtils';
import { MessageService } from 'primeng/api';
import { ApplicationSettingsExtended, NotificationModel, UserModel } from '../../../api/models';
import { Clipboard } from '@angular/cdk/clipboard';
import { NotificationService } from 'app/api/services';

@Component({
  selector: 'app-topbar',
  templateUrl: 'app-topbar.component.html',
  styleUrls: ['./app-menu.component.css'],
  providers: [TranslateService],
})
export class AppTopbarComponent implements OnInit {
  activeItem: number;
  public appSettings: ApplicationSettingsExtended;
  public megamenuItems: AppMenuItem[];
  notificationCount: string = "0";
  notificationList: NotificationModel[];
  public token: string;
  public isShowTokenDialog = false;
  isLinkedGoogle = true;
  model = {
    searchText: null
  };
  _currentUser = JSON.parse(localStorage.getItem('currentUser'));
  isAdmin = this._currentUser.loginType === 'Admin';

  constructor(
    public translate: TranslateService,
    public app: LayoutDefaultComponent,
    public applicationSettingsService: ApplicationSettingsService,
    private appMenuService: AppMenuService,
    private cdr: ChangeDetectorRef,
    public viewUtilsService: ViewUtilsService,
    private router: Router,
    private messageService: MessageService,
    private authenticationService: AuthenticationService,
    private googleAuthService: GoogleAuthService,
    private noti: AppNotificationServiceService,
    private clipboard: Clipboard,
    private notificationService: NotificationService
  ) {
    // translate.addLangs(['en','fr','th']);
    // translate.use(browserLang.match(/th|en/)? browserLang :'th');
    // this.translate = translate;
    const browserLang = translate.getBrowserLang();
    translate.use('th');
    this.translate.setDefaultLang('th');
    //
  }
  async ngOnInit() {
    this.appSettings = await this.applicationSettingsService.getApplicationSettings().toPromise();
    this.isLinkedGoogle = !!this.authenticationService.getUserInfo().googleId;
    this.megamenuItems = this.appMenuService.getMegaMenu();
    this.getMyNotification();
  }
  //   changeLanguage(lang: string) {
  //     this.translate.use(lang);
  // }

  mobileMegaMenuItemClick(index) {
    this.app.megaMenuMobileClick = true;
    this.activeItem = this.activeItem === index ? null : index;
  }
  logout() {
    // for mobile app
    if (AppPlatform.platform !== 'Web') {
      this.authenticationService.logout().subscribe(a => {
        console.log('Log out success.');
      });
    }

    // for web app
    else {
      this.authenticationService.logout().subscribe(a => {
        this.router.navigate(['/account/login']);
      });
    }
  }
  search(event) {
    if (this.authenticationService.getLoginType() === 'Admin' && !this.authenticationService.isSubrogating()) {
      this.router.navigate(['/admin/core/employee/list'], { queryParams: { first: 0, rows: 200, searchText: this.model.searchText } });
    } else {
      this.router.navigate(['/employee/core/employee/list'], { queryParams: { first: 0, rows: 200, searchText: this.model.searchText } });
    }
    this.model.searchText = '';
  }
  subrogateLogout() {
    this.authenticationService.subrogateLogout().subscribe(result => {
      this.viewUtilsService.gotoHomePage();
    });
  }

  getUserInfo(): UserModel {
    return this.authenticationService.getUserInfo();
  }

  isSubrogating(): boolean {
    return this.authenticationService.isSubrogating();
  }

  showToken() {
    this.token = this.authenticationService.getAccessToken();
    this.isShowTokenDialog = true;
  }

  linkGoogle() {
    this.googleAuthService.linkGoogleAccount().then(
      user => {
        this.noti.success('เชื่อมต่อสำเร็จ', '');
        this.authenticationService.saveGoogleIdToStorage(user.getId());
        this.isLinkedGoogle = true;
      },
      (err: HttpErrorResponse) => {
        console.log(err);
        this.noti.error(err.error.message, '');
      },
    );
  }

  getMyNotification() {
    this.notificationService.GetMyNotificationsList({ ItemPerPage: 20, Page: 1 }).subscribe(
      result => {
        this.notificationList = result.results.filter(a => a.isRead === false);
        this.notificationCount = this.notificationList.length.toString();
      },
      (err: HttpErrorResponse) => {
        console.log(err);
        //this.noti.error(err.error.message, '');
      },
    );
  }

  goToPage(url: string, id: number) {
    this.isReadNotification(id);
    this.router.navigate([url]);
  }

  isReadNotification(id: number) {
    this.notificationService.ReadNotification(id).subscribe(
      result => {
        this.getMyNotification();
      },
      (err: HttpErrorResponse) => {
        console.log(err);
      },
    );
  }

  copyText(textToCopy: string) {
    this.clipboard.copy(textToCopy);
  }
}
