/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
class HotFixService extends __BaseService {
  static readonly RecalculateAllOTPath = '/api/HotFix/RecalculateAllOT';
  static readonly RecalBenefitPath = '/api/HotFix/RecalBenefit';
  static readonly RecalculateAllLeaveQuotaPath = '/api/HotFix/RecalculateAllLeaveQuota';
  static readonly ResetPasswordAllEmployeePath = '/api/HotFix/ResetPasswordAllEmployee';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `HotFixService.RecalculateAllOTParams` containing the following parameters:
   *
   * - `toDate`:
   *
   * - `fromDate`:
   *
   * @return Success
   */
  RecalculateAllOTResponse(params: HotFixService.RecalculateAllOTParams): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.toDate != null) __params = __params.set('toDate', params.toDate.toString());
    if (params.fromDate != null) __params = __params.set('fromDate', params.fromDate.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/HotFix/RecalculateAllOT`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param params The `HotFixService.RecalculateAllOTParams` containing the following parameters:
   *
   * - `toDate`:
   *
   * - `fromDate`:
   *
   * @return Success
   */
  RecalculateAllOT(params: HotFixService.RecalculateAllOTParams): __Observable<string> {
    return this.RecalculateAllOTResponse(params).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param Benefitpolicy undefined
   * @return Success
   */
  RecalBenefitResponse(Benefitpolicy?: number): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Benefitpolicy != null) __params = __params.set('Benefitpolicy', Benefitpolicy.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/HotFix/RecalBenefit`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param Benefitpolicy undefined
   * @return Success
   */
  RecalBenefit(Benefitpolicy?: number): __Observable<string> {
    return this.RecalBenefitResponse(Benefitpolicy).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param params The `HotFixService.RecalculateAllLeaveQuotaParams` containing the following parameters:
   *
   * - `toDate`:
   *
   * - `fromDate`:
   *
   * @return Success
   */
  RecalculateAllLeaveQuotaResponse(params: HotFixService.RecalculateAllLeaveQuotaParams): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.toDate != null) __params = __params.set('toDate', params.toDate.toString());
    if (params.fromDate != null) __params = __params.set('fromDate', params.fromDate.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/HotFix/RecalculateAllLeaveQuota`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param params The `HotFixService.RecalculateAllLeaveQuotaParams` containing the following parameters:
   *
   * - `toDate`:
   *
   * - `fromDate`:
   *
   * @return Success
   */
  RecalculateAllLeaveQuota(params: HotFixService.RecalculateAllLeaveQuotaParams): __Observable<string> {
    return this.RecalculateAllLeaveQuotaResponse(params).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param params The `HotFixService.ResetPasswordAllEmployeeParams` containing the following parameters:
   *
   * - `key`:
   *
   * - `companyId`:
   *
   * @return Success
   */
  ResetPasswordAllEmployeeResponse(params: HotFixService.ResetPasswordAllEmployeeParams): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.key != null) __params = __params.set('key', params.key.toString());
    if (params.companyId != null) __params = __params.set('companyId', params.companyId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/HotFix/ResetPasswordAllEmployee`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param params The `HotFixService.ResetPasswordAllEmployeeParams` containing the following parameters:
   *
   * - `key`:
   *
   * - `companyId`:
   *
   * @return Success
   */
  ResetPasswordAllEmployee(params: HotFixService.ResetPasswordAllEmployeeParams): __Observable<string> {
    return this.ResetPasswordAllEmployeeResponse(params).pipe(
      __map(_r => _r.body as string)
    );
  }
}

module HotFixService {

  /**
   * Parameters for RecalculateAllOT
   */
  export interface RecalculateAllOTParams {
    toDate?: string;
    fromDate?: string;
  }

  /**
   * Parameters for RecalculateAllLeaveQuota
   */
  export interface RecalculateAllLeaveQuotaParams {
    toDate?: string;
    fromDate?: string;
  }

  /**
   * Parameters for ResetPasswordAllEmployee
   */
  export interface ResetPasswordAllEmployeeParams {
    key?: string;
    companyId?: number;
  }
}

export { HotFixService }
