<div class="layout-topbar no-print" *ngIf="appSettings">
  <div class="layout-topbar-wrapper">
    <div class="layout-topbar-left">
      <a href="#" class="sidebar-menu-button" (click)="app.onMenuButtonClick($event)" style="margin-left: 0px">
        <i class="pi pi-bars"></i>
      </a>
      <div class="layout-topbar-logo-wrapper">
        <a href="#" class="layout-topbar-logo">
          <div class="menu-logo"
            [ngStyle]="{'background-image': 'url(/api/document/' + appSettings.webLogoLargeImageId + '/download)'}">
          </div>
        </a>
      </div>

      <div>
        <h3>{{ appSettings.systemName }}{{ appSettings.systemNameSuffix }}</h3>
      </div>
      <a href="#" class="topbar-menu-mobile-button" (click)="app.onTopbarMobileMenuButtonClick($event)">
        <i class="pi pi-ellipsis-v"></i>
      </a>
    </div>
    <div class="layout-topbar-right fadeInDown">
      <ul class="layout-topbar-actions">
        <!--// List //-->
        <!-- <li #MyList class="topbar-item" [ngClass]="{'active-topmenuitem': app.activeTopbarItem === MyList}">
            <a href="#" (click)="app.onTopbarItemClick($event,MyList)">
              <i class="topbar-icon fas fa-bars"></i>
            </a>
            <ul class="fadeInDown">
              <li class="layout-submenu-item">
                <img src="assets/layout/images/topbar/ATHM-icon.png" alt="mirage-layout" width="35" />
                <div class="menu-text">
                  <p>Sample List </p>
                  <span> detail </span>
                </div>
              </li>
            </ul>
          </li> -->

        <!--// Application //-->
        <!--
        <li #MyApp class="topbar-item" [ngClass]="{ 'active-topmenuitem': app.activeTopbarItem === MyApp }"
          *ngIf="viewUtilsService.isShowByStage('preview', appSettings)">
          <a href="#" (click)="app.onTopbarItemClick($event, MyApp)">
            <i class="topbar-icon fas fa-th-large"></i>
          </a>
          <ul class="fadeInDown">
            <li class="deals">
              <ul class="grid p-dir-row" style="width: 360px; flex-direction: row">
                <li *ngFor="let item of megamenuItems"
                  style="text-align: center; height: 90px; width: 90px; display: block">
                  <div class="col">
                    <i [class]="item.icon" style="font-size: 20px; display: inline-block; width: 100%"></i>

                    <p style="font-size: 12px">{{ item.label }}</p>
                  </div>
                </li>
              </ul>
            </li>
          </ul>
        </li>
        -->
        <ng-container *ngIf="isAdmin"> 
          <li class="search-item topbar-item" [ngClass]="{ 'active-topmenuitem': app.activeTopbarItem === search }"
          *ngIf="viewUtilsService.isShowByStage('publish', appSettings)">
          <span class="p-input-icon-left">
            <i class="pi pi-search"></i>
            <input type="text" pInputText placeholder="ค้นหาพนักงาน" [(ngModel)]="model.searchText"
              (keydown.enter)="search($event)">
          </span>
        </li>
        </ng-container>
        <!--
        <li #message class="topbar-item" [ngClass]="{ 'active-topmenuitem': app.activeTopbarItem === message }"
          *ngIf="viewUtilsService.isShowByStage('dev', appSettings)">
          <a href="#" (click)="app.onTopbarItemClick($event, message)">
            <i class="topbar-icon fas fa-bullhorn"></i>
          </a>
          <ul class="fadeInDown">
            <li class="layout-submenu-header">
              <h1>{{ 'Topbar.announce' | translate }}</h1>
            </li>
            <li class="layout-submenu-item">
              <img src="assets/layout/images/topbar/ATHM-icon.png" alt="mirage-layout" width="35" />
              <div class="menu-text">
                <p>แจ้งปิดระบบในวันที่ 14 สิงหาคม 2565</p>
                <span> 10/08/2022 16:00 </span>
              </div>
            </li>
            <li class="layout-submenu-item">
              <img src="assets/layout/images/topbar/ATHM-icon.png" alt="mirage-layout" width="35" />
              <div class="menu-text">
                <p>Location ใหม่รหัส RG</p>
                <span> 01/05/2022 12:40 </span>
              </div>
            </li>
          </ul>
        </li>
      -->

        <!--// Notification //-->

        <li #gift class="topbar-item" [ngClass]="{ 'active-topmenuitem': app.activeTopbarItem === gift }"
          *ngIf="viewUtilsService.isShowByStage('dev', appSettings)">
          <a href="#" (click)="app.onTopbarItemClick($event, gift)">
            <!-- <i class="topbar-icon far fa-bell">{{notificationCount}}</i> -->
            <i class="pi pi-bell" pBadge [value]="notificationCount"></i>
          </a>
          <ul class="fadeInDown">
            <li class="layout-submenu-header">
              <h1>{{ 'Topbar.notifications.notifications_name' | translate }}</h1>
              x
            </li>
            <li class="layout-submenu-item" *ngFor="let notification of notificationList">
              <img src="assets/layout/images/topbar/ATHM-icon.png" alt="mirage-layout" width="35" />
              <div class="menu-text" (click)="goToPage(notification.link,notification.notificationId)">
                <p>{{ notification.notificationTypeName }}</p>
                <span> {{notification.description}} </span>
              </div>
            </li>
          </ul>
        </li>


        <li #profile class="topbar-item profile-item"
          [ngClass]="{ 'active-topmenuitem': app.activeTopbarItem === profile }">
          <a href="#" (click)="app.onTopbarItemClick($event, profile)" *ngIf="getUserInfo().userId != -1">
            <span class="profile-image-wrapper">
              <img src="/api/user/{{ getUserInfo().userId }}/image" alt="mirage-layout" />
            </span>
            <span class="profile-info-wrapper">
              <h3>{{ getUserInfo().firstname + ' ' + getUserInfo().lastname }}</h3>
            </span>
          </a>
          <ul class="profile-item-submenu fadeInDown">
            <li class="profile-submenu-header">
              <div class="profile">
                <img src="/api/user/{{ getUserInfo().userId }}/image" alt="mirage-layout" width="80" />
                <h1></h1>
              </div>
            </li>
            <li class="layout-submenu-item">
              <i class="fas fa-tag icon"></i>
              <div class="menu-text">
                <p>{{ getUserInfo().firstname + ' ' + getUserInfo().lastname }}</p>
              </div>
            </li>
            <!-- ซ่อนไปก่อนยังไม่ส่งข้อมูลมา

            <li class="layout-submenu-item">
              <i class="fas fa-suitcase icon"></i>
              <div class="menu-text">
                <p>รองกรรมการผู้จัดการฝ่ายบัญชีและการเงิน</p>
              </div>
            </li>

            <li class="layout-submenu-item">
              <i class="fas fa-bookmark icon"></i>
              <div class="menu-text">
                <p>สายบัญชีและการเงิน</p>
              </div>
            </li>

            <li class="layout-submenu-item">
              <i class="fas fa-map-marker-alt icon"></i>
              <div class="menu-text">
                <p>สำนักงานใหญ่</p>
              </div>
            </li>

            <li class="layout-submenu-item">
              <i class="fas fa-envelope icon"></i>
              <div class="menu-text">
                <p>somchai@mail.com</p>
              </div>
            </li>

            <li class="layout-submenu-item">
              <i class="fas fa-mobile icon"></i>
              <div class="menu-text">
                <p>086 927 8376</p>
              </div>
            </li>

            <li class="layout-submenu-item">
              <i class="fas fa-phone-alt icon"></i>
              <div class="menu-text">
                <p>1726</p>
              </div>
            </li>
          -->
            <li class="layout-submenu-footer">
              <button class="signout-button" (click)="logout()" *ngIf="!isSubrogating()">
                <i class="fas fa-sign-out-alt" style="display: inline"></i> Logout
              </button>
              <button class="signout-button" style="margin-right: 5%" (click)="subrogateLogout()"
                *ngIf="isSubrogating()">
                <i class="fas fa-sign-out-alt" style="display: inline"></i>Exit from Subrogation
              </button>
              <button class="signout-button" (click)="showToken()">
                <i class="fa fa-key" style="display: inline"></i> Show Token
              </button>
              <!-- <button
                *ngIf="!isLinkedGoogle && viewUtilsService.isShowByStage('dev') | async"
                class="signout-button"
                (click)="linkGoogle()"
              >
                <i class="fab fa-google" style="display: inline"></i>Link Google
              </button> -->
            </li>
          </ul>
        </li>
        <!-- <li>
            <a href="#" class="layout-rightpanel-button" (click)="app.onRightPanelButtonClick($event)">
              <i class="pi pi-arrow-left"></i>
            </a>
          </li> -->
      </ul>

      <ul class="profile-mobile-wrapper">
        <li #mobileProfile class="topbar-item profile-item" [ngClass]="{
            'active-topmenuitem': app.activeTopbarItem === mobileProfile
          }">
          <a href="#" (click)="app.onTopbarItemClick($event, mobileProfile)">
            <span class="profile-image-wrapper">
              <img src="/api/user/{{ getUserInfo().userId }}/image" alt="mirage-layout" />
            </span>
            <span class="profile-info-wrapper">
              <h3>{{ getUserInfo().firstname + ' ' + getUserInfo().lastname }}</h3>
            </span>
          </a>
          <!-- <ul class="fadeInDown">
            <li class="profile-submenu-header">
              <div class="performance">
                <span>Weekly Performance</span>
                <img src="assets/layout/images/topbar/asset-bars.svg" alt="mirage-layout" />
              </div>
              <div class="profile">
                <img src="/api/user/{{ getUserInfo().userId }}/image" alt="mirage-layout" width="45" />
                <h1></h1>
              </div>
            </li>
            <li>
              <i class="pi pi-list icon icon-1"></i>
              <div class="menu-text">
                <p>Tasks</p>
                <span>3 open issues</span>
              </div>
              <i class="pi pi-angle-right"></i>
            </li>
            <li>
              <i class="pi pi-shopping-cart icon icon-2"></i>
              <div class="menu-text">
                <p>Payments</p>
                <span>24 new</span>
              </div>
              <i class="pi pi-angle-right"></i>
            </li>
            <li>
              <i class="pi pi-users icon icon-3"></i>
              <div class="menu-text">
                <p>Clients</p>
                <span>+80%</span>
              </div>
              <i class="pi pi-angle-right"></i>
            </li>
            <li class="layout-submenu-footer">
              <button class="signout-button">{{ 'Topbar.profile.profile_logout' | translate }}</button>
            </li>
          </ul>-->
        </li>
        <div style="text-align: center">
          <div (click)="logout()" *ngIf="!isSubrogating()"><i class="fas fa-sign-out-alt"></i> Logout</div>

          <div (click)="subrogateLogout()" *ngIf="isSubrogating()">
            <i class="fas fa-sign-out-alt"></i> Exit from Subrogation
          </div>
        </div>
      </ul>
    </div>
  </div>
</div>

<p-dialog header="Access Token" [(visible)]="isShowTokenDialog" position="top">
  <p style="word-break: break-all; max-width: 600px">Your token is "{{ token }}".</p>
  <ng-template pTemplate="footer">
    <p-button icon="pi pi-copy" label="Copy" (click)="copyText(token)" styleClass="p-button-secondary">
    </p-button>
    <p-button icon="pi pi-check" (click)="isShowTokenDialog = false" label="Close" styleClass="p-button-text">
    </p-button>
  </ng-template>
</p-dialog>
