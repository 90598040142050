/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { BenefitPolicyModelPaginationResult } from '../models/benefit-policy-model-pagination-result';
import { BenefitPolicyModel } from '../models/benefit-policy-model';
import { LeaveQuotasModel } from '../models/leave-quotas-model';
@Injectable({
  providedIn: 'root',
})
class BenefitPolicyService extends __BaseService {
  static readonly GetBenefitPolicyListPath = '/api/BenefitPolicy';
  static readonly CreateBenefitPolicyPath = '/api/BenefitPolicy';
  static readonly UpdateBenefitPolicyPath = '/api/BenefitPolicy';
  static readonly GetBenefitPolicyByIdPath = '/api/BenefitPolicy/{benefitPolicyId}';
  static readonly DeleteBenefitPolicyPath = '/api/BenefitPolicy/{benefitPolicyId}';
  static readonly GetMyLeaveQuotasPath = '/api/BenefitPolicy/myleavequotas/{year}';
  static readonly GenerateNewYearQuotaPath = '/api/BenefitPolicy/run/generate-new-year-quota';
  static readonly GenerateNewQuotaPath = '/api/BenefitPolicy/run/generate-new-quota';
  static readonly GenerateNewQuotaNextYearPath = '/api/BenefitPolicy/run/generate-new-quota-next-year';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `BenefitPolicyService.GetBenefitPolicyListParams` containing the following parameters:
   *
   * - `Sorting`:
   *
   * - `SearchText`:
   *
   * - `Page`:
   *
   * - `JobGradeId`:
   *
   * - `ItemsToSkip`:
   *
   * - `ItemPerPage`:
   *
   * - `CompanyId`:
   *
   * @return Success
   */
  GetBenefitPolicyListResponse(params: BenefitPolicyService.GetBenefitPolicyListParams): __Observable<__StrictHttpResponse<BenefitPolicyModelPaginationResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Sorting != null) __params = __params.set('Sorting', params.Sorting.toString());
    if (params.SearchText != null) __params = __params.set('SearchText', params.SearchText.toString());
    if (params.Page != null) __params = __params.set('Page', params.Page.toString());
    if (params.JobGradeId != null) __params = __params.set('JobGradeId', params.JobGradeId.toString());
    if (params.ItemsToSkip != null) __params = __params.set('ItemsToSkip', params.ItemsToSkip.toString());
    if (params.ItemPerPage != null) __params = __params.set('ItemPerPage', params.ItemPerPage.toString());
    if (params.CompanyId != null) __params = __params.set('CompanyId', params.CompanyId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/BenefitPolicy`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<BenefitPolicyModelPaginationResult>;
      })
    );
  }
  /**
   * @param params The `BenefitPolicyService.GetBenefitPolicyListParams` containing the following parameters:
   *
   * - `Sorting`:
   *
   * - `SearchText`:
   *
   * - `Page`:
   *
   * - `JobGradeId`:
   *
   * - `ItemsToSkip`:
   *
   * - `ItemPerPage`:
   *
   * - `CompanyId`:
   *
   * @return Success
   */
  GetBenefitPolicyList(params: BenefitPolicyService.GetBenefitPolicyListParams): __Observable<BenefitPolicyModelPaginationResult> {
    return this.GetBenefitPolicyListResponse(params).pipe(
      __map(_r => _r.body as BenefitPolicyModelPaginationResult)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  CreateBenefitPolicyResponse(body?: BenefitPolicyModel): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/BenefitPolicy`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  CreateBenefitPolicy(body?: BenefitPolicyModel): __Observable<string> {
    return this.CreateBenefitPolicyResponse(body).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  UpdateBenefitPolicyResponse(body?: BenefitPolicyModel): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/BenefitPolicy`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  UpdateBenefitPolicy(body?: BenefitPolicyModel): __Observable<string> {
    return this.UpdateBenefitPolicyResponse(body).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param benefitPolicyId undefined
   * @return Success
   */
  GetBenefitPolicyByIdResponse(benefitPolicyId: number): __Observable<__StrictHttpResponse<BenefitPolicyModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/BenefitPolicy/${encodeURIComponent(String(benefitPolicyId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<BenefitPolicyModel>;
      })
    );
  }
  /**
   * @param benefitPolicyId undefined
   * @return Success
   */
  GetBenefitPolicyById(benefitPolicyId: number): __Observable<BenefitPolicyModel> {
    return this.GetBenefitPolicyByIdResponse(benefitPolicyId).pipe(
      __map(_r => _r.body as BenefitPolicyModel)
    );
  }

  /**
   * @param benefitPolicyId undefined
   * @return Success
   */
  DeleteBenefitPolicyResponse(benefitPolicyId: number): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/BenefitPolicy/${encodeURIComponent(String(benefitPolicyId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param benefitPolicyId undefined
   * @return Success
   */
  DeleteBenefitPolicy(benefitPolicyId: number): __Observable<string> {
    return this.DeleteBenefitPolicyResponse(benefitPolicyId).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param year undefined
   * @return Success
   */
  GetMyLeaveQuotasResponse(year: number): __Observable<__StrictHttpResponse<LeaveQuotasModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/BenefitPolicy/myleavequotas/${encodeURIComponent(String(year))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LeaveQuotasModel>;
      })
    );
  }
  /**
   * @param year undefined
   * @return Success
   */
  GetMyLeaveQuotas(year: number): __Observable<LeaveQuotasModel> {
    return this.GetMyLeaveQuotasResponse(year).pipe(
      __map(_r => _r.body as LeaveQuotasModel)
    );
  }

  /**
   * @return Success
   */
  GenerateNewYearQuotaResponse(): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/BenefitPolicy/run/generate-new-year-quota`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @return Success
   */
  GenerateNewYearQuota(): __Observable<string> {
    return this.GenerateNewYearQuotaResponse().pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @return Success
   */
  GenerateNewQuotaResponse(): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/BenefitPolicy/run/generate-new-quota`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @return Success
   */
  GenerateNewQuota(): __Observable<string> {
    return this.GenerateNewQuotaResponse().pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @return Success
   */
  GenerateNewQuotaNextYearResponse(): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/BenefitPolicy/run/generate-new-quota-next-year`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @return Success
   */
  GenerateNewQuotaNextYear(): __Observable<string> {
    return this.GenerateNewQuotaNextYearResponse().pipe(
      __map(_r => _r.body as string)
    );
  }
}

module BenefitPolicyService {

  /**
   * Parameters for GetBenefitPolicyList
   */
  export interface GetBenefitPolicyListParams {
    Sorting?: string;
    SearchText?: string;
    Page?: number;
    JobGradeId?: number;
    ItemsToSkip?: number;
    ItemPerPage?: number;
    CompanyId?: number;
  }
}

export { BenefitPolicyService }
