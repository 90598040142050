import { DateTime, Interval } from 'luxon';

export interface IDateDiff{
  year: number,
  month: number,
  day: number
}

export const toLastDayOfMonth = (date: Date): Date => {
  return new Date(date.getFullYear(), date.getMonth() + 1, 0);
};

export function calculateDifferenceDateAsString(from: string, to?: string, includeLatestDay:boolean = false): IDateDiff{

  return calculateDifferenceDateAsDateTime(DateTime.fromISO(from, {zone: 'utc', setZone: true}), to ? DateTime.fromISO(to, {zone: 'utc', setZone: true}) : DateTime.now(), includeLatestDay);
}

export function calculateDifferenceDate(from: Date, to?: Date, includeLatestDay:boolean = false): IDateDiff{
  return calculateDifferenceDateAsDateTime(DateTime.fromJSDate(from), DateTime.fromJSDate(to || new Date()), includeLatestDay);
}

export function calculateDifferenceDateAsDateTime(from: DateTime, to: DateTime, includeLatestDay:boolean = false):IDateDiff{
  if(includeLatestDay) to = to.plus({days:1});
  const interval = Interval.fromDateTimes(from, to);
  const { years, months, days } = interval.toDuration(['years', 'months', 'days']).toObject();
  return { 
      year: Math.floor(years || 0), 
      month: Math.floor(months || 0), 
      day: Math.floor(days || 0) 
  };
}