/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { EmployeeTimesheetModelPaginationResult } from '../models/employee-timesheet-model-pagination-result';
import { EmployeeTimesheetSummaryModel } from '../models/employee-timesheet-summary-model';
@Injectable({
  providedIn: 'root',
})
class TimesheetService extends __BaseService {
  static readonly GetTimeSheetPath = '/api/Timesheet';
  static readonly GetMyTimesheetPath = '/api/mytimesheet';
  static readonly GetEmployeeTimesheetSummaryPath = '/api/Employee/{employeeId}/timesheetsummary';
  static readonly GetMySubordinateTimesheetPath = '/api/mysubOrdinateTimesheet';
  static readonly LoadTimeEventFromFilePath = '/api/run/LoadTimeEventFromFile';
  static readonly LoadTimeEventFromTextFilePath = '/api/run/LoadTimeEventFromTextFile';
  static readonly CalculateTimesheetbyDatePath = '/api/run/CalculateTimesheet';
  static readonly CreateFixedOTForAutomatchPath = '/api/run/CreateFixedOTForAutomatch';
  static readonly CalculateTimesheetForTestPath = '/api/run/CalculateTimesheetFortest';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `TimesheetService.GetTimeSheetParams` containing the following parameters:
   *
   * - `UnitId`:
   *
   * - `TimeAttendanceStatusId`:
   *
   * - `Sorting`:
   *
   * - `SearchText`:
   *
   * - `PositionId`:
   *
   * - `Page`:
   *
   * - `OtStatusId`:
   *
   * - `LocationId`:
   *
   * - `ItemsToSkip`:
   *
   * - `ItemPerPage`:
   *
   * - `IsOnlySubOrdinate`:
   *
   * - `IsExpiredEmployee`:
   *
   * - `IsAllEmployee`:
   *
   * - `EventStart`:
   *
   * - `EventEnd`:
   *
   * - `EmployeeTypeId`:
   *
   * - `EmployeeType2Id`:
   *
   * - `EmployeeName`:
   *
   * - `EmployeeId`:
   *
   * - `EmployeeCode`:
   *
   * - `CompanyId`:
   *
   * - `BranchId`:
   *
   * @return Success
   */
  GetTimeSheetResponse(params: TimesheetService.GetTimeSheetParams): __Observable<__StrictHttpResponse<EmployeeTimesheetModelPaginationResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.UnitId != null) __params = __params.set('UnitId', params.UnitId.toString());
    if (params.TimeAttendanceStatusId != null) __params = __params.set('TimeAttendanceStatusId', params.TimeAttendanceStatusId.toString());
    if (params.Sorting != null) __params = __params.set('Sorting', params.Sorting.toString());
    if (params.SearchText != null) __params = __params.set('SearchText', params.SearchText.toString());
    if (params.PositionId != null) __params = __params.set('PositionId', params.PositionId.toString());
    if (params.Page != null) __params = __params.set('Page', params.Page.toString());
    if (params.OtStatusId != null) __params = __params.set('OtStatusId', params.OtStatusId.toString());
    if (params.LocationId != null) __params = __params.set('LocationId', params.LocationId.toString());
    if (params.ItemsToSkip != null) __params = __params.set('ItemsToSkip', params.ItemsToSkip.toString());
    if (params.ItemPerPage != null) __params = __params.set('ItemPerPage', params.ItemPerPage.toString());
    if (params.IsOnlySubOrdinate != null) __params = __params.set('IsOnlySubOrdinate', params.IsOnlySubOrdinate.toString());
    if (params.IsExpiredEmployee != null) __params = __params.set('IsExpiredEmployee', params.IsExpiredEmployee.toString());
    if (params.IsAllEmployee != null) __params = __params.set('IsAllEmployee', params.IsAllEmployee.toString());
    if (params.EventStart != null) __params = __params.set('EventStart', params.EventStart.toString());
    if (params.EventEnd != null) __params = __params.set('EventEnd', params.EventEnd.toString());
    if (params.EmployeeTypeId != null) __params = __params.set('EmployeeTypeId', params.EmployeeTypeId.toString());
    if (params.EmployeeType2Id != null) __params = __params.set('EmployeeType2Id', params.EmployeeType2Id.toString());
    if (params.EmployeeName != null) __params = __params.set('EmployeeName', params.EmployeeName.toString());
    if (params.EmployeeId != null) __params = __params.set('EmployeeId', params.EmployeeId.toString());
    if (params.EmployeeCode != null) __params = __params.set('EmployeeCode', params.EmployeeCode.toString());
    if (params.CompanyId != null) __params = __params.set('CompanyId', params.CompanyId.toString());
    if (params.BranchId != null) __params = __params.set('BranchId', params.BranchId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Timesheet`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EmployeeTimesheetModelPaginationResult>;
      })
    );
  }
  /**
   * @param params The `TimesheetService.GetTimeSheetParams` containing the following parameters:
   *
   * - `UnitId`:
   *
   * - `TimeAttendanceStatusId`:
   *
   * - `Sorting`:
   *
   * - `SearchText`:
   *
   * - `PositionId`:
   *
   * - `Page`:
   *
   * - `OtStatusId`:
   *
   * - `LocationId`:
   *
   * - `ItemsToSkip`:
   *
   * - `ItemPerPage`:
   *
   * - `IsOnlySubOrdinate`:
   *
   * - `IsExpiredEmployee`:
   *
   * - `IsAllEmployee`:
   *
   * - `EventStart`:
   *
   * - `EventEnd`:
   *
   * - `EmployeeTypeId`:
   *
   * - `EmployeeType2Id`:
   *
   * - `EmployeeName`:
   *
   * - `EmployeeId`:
   *
   * - `EmployeeCode`:
   *
   * - `CompanyId`:
   *
   * - `BranchId`:
   *
   * @return Success
   */
  GetTimeSheet(params: TimesheetService.GetTimeSheetParams): __Observable<EmployeeTimesheetModelPaginationResult> {
    return this.GetTimeSheetResponse(params).pipe(
      __map(_r => _r.body as EmployeeTimesheetModelPaginationResult)
    );
  }

  /**
   * @param params The `TimesheetService.GetMyTimesheetParams` containing the following parameters:
   *
   * - `Sorting`:
   *
   * - `SearchText`:
   *
   * - `Page`:
   *
   * - `ItemsToSkip`:
   *
   * - `ItemPerPage`:
   *
   * - `EventStart`:
   *
   * - `EventEnd`:
   *
   * @return Success
   */
  GetMyTimesheetResponse(params: TimesheetService.GetMyTimesheetParams): __Observable<__StrictHttpResponse<EmployeeTimesheetModelPaginationResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Sorting != null) __params = __params.set('Sorting', params.Sorting.toString());
    if (params.SearchText != null) __params = __params.set('SearchText', params.SearchText.toString());
    if (params.Page != null) __params = __params.set('Page', params.Page.toString());
    if (params.ItemsToSkip != null) __params = __params.set('ItemsToSkip', params.ItemsToSkip.toString());
    if (params.ItemPerPage != null) __params = __params.set('ItemPerPage', params.ItemPerPage.toString());
    if (params.EventStart != null) __params = __params.set('EventStart', params.EventStart.toString());
    if (params.EventEnd != null) __params = __params.set('EventEnd', params.EventEnd.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/mytimesheet`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EmployeeTimesheetModelPaginationResult>;
      })
    );
  }
  /**
   * @param params The `TimesheetService.GetMyTimesheetParams` containing the following parameters:
   *
   * - `Sorting`:
   *
   * - `SearchText`:
   *
   * - `Page`:
   *
   * - `ItemsToSkip`:
   *
   * - `ItemPerPage`:
   *
   * - `EventStart`:
   *
   * - `EventEnd`:
   *
   * @return Success
   */
  GetMyTimesheet(params: TimesheetService.GetMyTimesheetParams): __Observable<EmployeeTimesheetModelPaginationResult> {
    return this.GetMyTimesheetResponse(params).pipe(
      __map(_r => _r.body as EmployeeTimesheetModelPaginationResult)
    );
  }

  /**
   * @param employeeId undefined
   * @return Success
   */
  GetEmployeeTimesheetSummaryResponse(employeeId: number): __Observable<__StrictHttpResponse<Array<EmployeeTimesheetSummaryModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Employee/${encodeURIComponent(String(employeeId))}/timesheetsummary`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<EmployeeTimesheetSummaryModel>>;
      })
    );
  }
  /**
   * @param employeeId undefined
   * @return Success
   */
  GetEmployeeTimesheetSummary(employeeId: number): __Observable<Array<EmployeeTimesheetSummaryModel>> {
    return this.GetEmployeeTimesheetSummaryResponse(employeeId).pipe(
      __map(_r => _r.body as Array<EmployeeTimesheetSummaryModel>)
    );
  }

  /**
   * @param params The `TimesheetService.GetMySubordinateTimesheetParams` containing the following parameters:
   *
   * - `UnitId`:
   *
   * - `TimeAttendanceStatusId`:
   *
   * - `Sorting`:
   *
   * - `SearchText`:
   *
   * - `PositionId`:
   *
   * - `Page`:
   *
   * - `OtStatusId`:
   *
   * - `LocationId`:
   *
   * - `ItemsToSkip`:
   *
   * - `ItemPerPage`:
   *
   * - `IsOnlySubOrdinate`:
   *
   * - `IsExpiredEmployee`:
   *
   * - `IsAllEmployee`:
   *
   * - `EventStart`:
   *
   * - `EventEnd`:
   *
   * - `EmployeeTypeId`:
   *
   * - `EmployeeType2Id`:
   *
   * - `EmployeeName`:
   *
   * - `EmployeeId`:
   *
   * - `EmployeeCode`:
   *
   * - `CompanyId`:
   *
   * - `BranchId`:
   *
   * @return Success
   */
  GetMySubordinateTimesheetResponse(params: TimesheetService.GetMySubordinateTimesheetParams): __Observable<__StrictHttpResponse<EmployeeTimesheetModelPaginationResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.UnitId != null) __params = __params.set('UnitId', params.UnitId.toString());
    if (params.TimeAttendanceStatusId != null) __params = __params.set('TimeAttendanceStatusId', params.TimeAttendanceStatusId.toString());
    if (params.Sorting != null) __params = __params.set('Sorting', params.Sorting.toString());
    if (params.SearchText != null) __params = __params.set('SearchText', params.SearchText.toString());
    if (params.PositionId != null) __params = __params.set('PositionId', params.PositionId.toString());
    if (params.Page != null) __params = __params.set('Page', params.Page.toString());
    if (params.OtStatusId != null) __params = __params.set('OtStatusId', params.OtStatusId.toString());
    if (params.LocationId != null) __params = __params.set('LocationId', params.LocationId.toString());
    if (params.ItemsToSkip != null) __params = __params.set('ItemsToSkip', params.ItemsToSkip.toString());
    if (params.ItemPerPage != null) __params = __params.set('ItemPerPage', params.ItemPerPage.toString());
    if (params.IsOnlySubOrdinate != null) __params = __params.set('IsOnlySubOrdinate', params.IsOnlySubOrdinate.toString());
    if (params.IsExpiredEmployee != null) __params = __params.set('IsExpiredEmployee', params.IsExpiredEmployee.toString());
    if (params.IsAllEmployee != null) __params = __params.set('IsAllEmployee', params.IsAllEmployee.toString());
    if (params.EventStart != null) __params = __params.set('EventStart', params.EventStart.toString());
    if (params.EventEnd != null) __params = __params.set('EventEnd', params.EventEnd.toString());
    if (params.EmployeeTypeId != null) __params = __params.set('EmployeeTypeId', params.EmployeeTypeId.toString());
    if (params.EmployeeType2Id != null) __params = __params.set('EmployeeType2Id', params.EmployeeType2Id.toString());
    if (params.EmployeeName != null) __params = __params.set('EmployeeName', params.EmployeeName.toString());
    if (params.EmployeeId != null) __params = __params.set('EmployeeId', params.EmployeeId.toString());
    if (params.EmployeeCode != null) __params = __params.set('EmployeeCode', params.EmployeeCode.toString());
    if (params.CompanyId != null) __params = __params.set('CompanyId', params.CompanyId.toString());
    if (params.BranchId != null) __params = __params.set('BranchId', params.BranchId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/mysubOrdinateTimesheet`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EmployeeTimesheetModelPaginationResult>;
      })
    );
  }
  /**
   * @param params The `TimesheetService.GetMySubordinateTimesheetParams` containing the following parameters:
   *
   * - `UnitId`:
   *
   * - `TimeAttendanceStatusId`:
   *
   * - `Sorting`:
   *
   * - `SearchText`:
   *
   * - `PositionId`:
   *
   * - `Page`:
   *
   * - `OtStatusId`:
   *
   * - `LocationId`:
   *
   * - `ItemsToSkip`:
   *
   * - `ItemPerPage`:
   *
   * - `IsOnlySubOrdinate`:
   *
   * - `IsExpiredEmployee`:
   *
   * - `IsAllEmployee`:
   *
   * - `EventStart`:
   *
   * - `EventEnd`:
   *
   * - `EmployeeTypeId`:
   *
   * - `EmployeeType2Id`:
   *
   * - `EmployeeName`:
   *
   * - `EmployeeId`:
   *
   * - `EmployeeCode`:
   *
   * - `CompanyId`:
   *
   * - `BranchId`:
   *
   * @return Success
   */
  GetMySubordinateTimesheet(params: TimesheetService.GetMySubordinateTimesheetParams): __Observable<EmployeeTimesheetModelPaginationResult> {
    return this.GetMySubordinateTimesheetResponse(params).pipe(
      __map(_r => _r.body as EmployeeTimesheetModelPaginationResult)
    );
  }

  /**
   * @param params The `TimesheetService.LoadTimeEventFromFileParams` containing the following parameters:
   *
   * - `sourceId`:
   *
   * - `file`:
   *
   * @return Success
   */
  LoadTimeEventFromFileResponse(params: TimesheetService.LoadTimeEventFromFileParams): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let __formData = new FormData();
    __body = __formData;
    if (params.sourceId != null) __params = __params.set('sourceId', params.sourceId.toString());
    if (params.file != null) { __formData.append('file', params.file as string | Blob);}
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/run/LoadTimeEventFromFile`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param params The `TimesheetService.LoadTimeEventFromFileParams` containing the following parameters:
   *
   * - `sourceId`:
   *
   * - `file`:
   *
   * @return Success
   */
  LoadTimeEventFromFile(params: TimesheetService.LoadTimeEventFromFileParams): __Observable<string> {
    return this.LoadTimeEventFromFileResponse(params).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param params The `TimesheetService.LoadTimeEventFromTextFileParams` containing the following parameters:
   *
   * - `sourceId`:
   *
   * - `file`:
   *
   * @return Success
   */
  LoadTimeEventFromTextFileResponse(params: TimesheetService.LoadTimeEventFromTextFileParams): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let __formData = new FormData();
    __body = __formData;
    if (params.sourceId != null) __params = __params.set('sourceId', params.sourceId.toString());
    if (params.file != null) { __formData.append('file', params.file as string | Blob);}
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/run/LoadTimeEventFromTextFile`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param params The `TimesheetService.LoadTimeEventFromTextFileParams` containing the following parameters:
   *
   * - `sourceId`:
   *
   * - `file`:
   *
   * @return Success
   */
  LoadTimeEventFromTextFile(params: TimesheetService.LoadTimeEventFromTextFileParams): __Observable<string> {
    return this.LoadTimeEventFromTextFileResponse(params).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param params The `TimesheetService.CalculateTimesheetbyDateParams` containing the following parameters:
   *
   * - `to`:
   *
   * - `from`:
   *
   * - `employeeId`:
   *
   * - `companyId`:
   *
   * @return Success
   */
  CalculateTimesheetbyDateResponse(params: TimesheetService.CalculateTimesheetbyDateParams): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.to != null) __params = __params.set('to', params.to.toString());
    if (params.from != null) __params = __params.set('from', params.from.toString());
    if (params.employeeId != null) __params = __params.set('employeeId', params.employeeId.toString());
    if (params.companyId != null) __params = __params.set('companyId', params.companyId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/run/CalculateTimesheet`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param params The `TimesheetService.CalculateTimesheetbyDateParams` containing the following parameters:
   *
   * - `to`:
   *
   * - `from`:
   *
   * - `employeeId`:
   *
   * - `companyId`:
   *
   * @return Success
   */
  CalculateTimesheetbyDate(params: TimesheetService.CalculateTimesheetbyDateParams): __Observable<string> {
    return this.CalculateTimesheetbyDateResponse(params).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param params The `TimesheetService.CreateFixedOTForAutomatchParams` containing the following parameters:
   *
   * - `to`:
   *
   * - `shiftId`:
   *
   * - `from`:
   *
   * @return Success
   */
  CreateFixedOTForAutomatchResponse(params: TimesheetService.CreateFixedOTForAutomatchParams): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.to != null) __params = __params.set('to', params.to.toString());
    if (params.shiftId != null) __params = __params.set('shiftId', params.shiftId.toString());
    if (params.from != null) __params = __params.set('from', params.from.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/run/CreateFixedOTForAutomatch`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param params The `TimesheetService.CreateFixedOTForAutomatchParams` containing the following parameters:
   *
   * - `to`:
   *
   * - `shiftId`:
   *
   * - `from`:
   *
   * @return Success
   */
  CreateFixedOTForAutomatch(params: TimesheetService.CreateFixedOTForAutomatchParams): __Observable<string> {
    return this.CreateFixedOTForAutomatchResponse(params).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param params The `TimesheetService.CalculateTimesheetForTestParams` containing the following parameters:
   *
   * - `to`:
   *
   * - `from`:
   *
   * - `employeeId`:
   *
   * @return Success
   */
  CalculateTimesheetForTestResponse(params: TimesheetService.CalculateTimesheetForTestParams): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.to != null) __params = __params.set('to', params.to.toString());
    if (params.from != null) __params = __params.set('from', params.from.toString());
    if (params.employeeId != null) __params = __params.set('employeeId', params.employeeId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/run/CalculateTimesheetFortest`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param params The `TimesheetService.CalculateTimesheetForTestParams` containing the following parameters:
   *
   * - `to`:
   *
   * - `from`:
   *
   * - `employeeId`:
   *
   * @return Success
   */
  CalculateTimesheetForTest(params: TimesheetService.CalculateTimesheetForTestParams): __Observable<string> {
    return this.CalculateTimesheetForTestResponse(params).pipe(
      __map(_r => _r.body as string)
    );
  }
}

module TimesheetService {

  /**
   * Parameters for GetTimeSheet
   */
  export interface GetTimeSheetParams {
    UnitId?: number;
    TimeAttendanceStatusId?: any;
    Sorting?: string;
    SearchText?: string;
    PositionId?: number;
    Page?: number;
    OtStatusId?: any;
    LocationId?: number;
    ItemsToSkip?: number;
    ItemPerPage?: number;
    IsOnlySubOrdinate?: boolean;
    IsExpiredEmployee?: boolean;
    IsAllEmployee?: boolean;
    EventStart?: string;
    EventEnd?: string;
    EmployeeTypeId?: string;
    EmployeeType2Id?: string;
    EmployeeName?: string;
    EmployeeId?: number;
    EmployeeCode?: string;
    CompanyId?: number;
    BranchId?: number;
  }

  /**
   * Parameters for GetMyTimesheet
   */
  export interface GetMyTimesheetParams {
    Sorting?: string;
    SearchText?: string;
    Page?: number;
    ItemsToSkip?: number;
    ItemPerPage?: number;
    EventStart?: string;
    EventEnd?: string;
  }

  /**
   * Parameters for GetMySubordinateTimesheet
   */
  export interface GetMySubordinateTimesheetParams {
    UnitId?: number;
    TimeAttendanceStatusId?: any;
    Sorting?: string;
    SearchText?: string;
    PositionId?: number;
    Page?: number;
    OtStatusId?: any;
    LocationId?: number;
    ItemsToSkip?: number;
    ItemPerPage?: number;
    IsOnlySubOrdinate?: boolean;
    IsExpiredEmployee?: boolean;
    IsAllEmployee?: boolean;
    EventStart?: string;
    EventEnd?: string;
    EmployeeTypeId?: string;
    EmployeeType2Id?: string;
    EmployeeName?: string;
    EmployeeId?: number;
    EmployeeCode?: string;
    CompanyId?: number;
    BranchId?: number;
  }

  /**
   * Parameters for LoadTimeEventFromFile
   */
  export interface LoadTimeEventFromFileParams {
    sourceId?: string;
    file?: Blob;
  }

  /**
   * Parameters for LoadTimeEventFromTextFile
   */
  export interface LoadTimeEventFromTextFileParams {
    sourceId?: string;
    file?: Blob;
  }

  /**
   * Parameters for CalculateTimesheetbyDate
   */
  export interface CalculateTimesheetbyDateParams {
    to?: string;
    from?: string;
    employeeId?: number;
    companyId?: number;
  }

  /**
   * Parameters for CreateFixedOTForAutomatch
   */
  export interface CreateFixedOTForAutomatchParams {
    to?: string;
    shiftId?: number;
    from?: string;
  }

  /**
   * Parameters for CalculateTimesheetForTest
   */
  export interface CalculateTimesheetForTestParams {
    to?: string;
    from?: string;
    employeeId?: number;
  }
}

export { TimesheetService }
