/* tslint:disable */
type EBenefitConditionType =
  'Now' |
  'AfterTerms' |
  'Portion' |
  'NextYear';
module EBenefitConditionType {
  export const NOW: EBenefitConditionType = 'Now';
  export const AFTER_TERMS: EBenefitConditionType = 'AfterTerms';
  export const PORTION: EBenefitConditionType = 'Portion';
  export const NEXT_YEAR: EBenefitConditionType = 'NextYear';
  export function values(): EBenefitConditionType[] {
    return [
      NOW,
      AFTER_TERMS,
      PORTION,
      NEXT_YEAR
    ];
  }
}

export { EBenefitConditionType }